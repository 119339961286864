<template>
  <div>
    <div class="alert alert-danger mt-4" role="alert" v-if="!isAllCompleted">
      <strong>Data belum lengkap terisi, diantaranya:</strong>
      <ul style="list-style-type: disc; padding-left: 1rem">
        <li v-if="!isFotodiriCompleted">Foto diri</li>
        <li v-if="!isKeluargaCompleted">Data orang tua / wali</li>
        <li v-if="isKeluargaCompleted && !isIbuKandungCompleted">
          Ibu Kandung harus diisi
        </li>
        <li v-if="!isBerkasCompleted">
          Scan berkas Asli (bukan fotokopi)
          <ul style="list-style-type: '- '; padding-left: 1rem">
            <li v-if="!hasBerkasAkta">Scan akte kelahiran peserta didik</li>
            <li v-if="!hasBerkasKK">Scan kartu keluarga (KK)</li>
            <li v-if="!hasBerkasKTP">Scan KTP ortu / wali</li>
            <li>
              Scan ijazah terakhir / SHUN
              <span class="font-italic">(opsional jika sudah ada)</span>
            </li>
          </ul>
        </li>
        <li v-if="!isMutasiBerkasCompleted">
          Karena jenis daftar MUTASI, lengkapi juga dengan berkas<br />
          <ul style="list-style-type: '- '; padding-left: 1rem">
            <li>Surat Keterangan Menerima dari Sekolah Tujuan</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="card mb-4 mt-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h6 class="mb-0">
          <fa-icon :icon="['fa', 'user']" class="mr-3 text-secondary" />Biodata
          Peserta Didik
        </h6>
        <button
          v-if="!editmode"
          @click="editTrigger"
          class="btn btn-sm btn-primary"
        >
          <fa-icon :icon="['fa', 'pen']" class="mr-2" />Ubah
        </button>
        <div v-if="editmode" class="float-right">
          <button @click="action" class="btn btn-sm btn-success mx-1">
            <fa-icon :icon="['fa', 'save']" class="mr-2" />Simpan
          </button>
          <button @click="editmode = false" class="btn btn-sm btn-danger ml-1">
            <fa-icon :icon="['fa', 'times']" class="mr-2" />Batal
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="d-md-flex flex-row-reverse">
          <div class="flex-fill text-center mb-3">
            <div
              v-if="biodata.akun_pendaftar"
              class="d-inline-block img-thumbnail"
            >
              <!-- <span>{{ biodata.akun_pendaftar.qrcode_url }}</span> -->
              <img v-auth-image="biodata.akun_pendaftar.qrcode_url" alt="" />
            </div>
          </div>
          <div class="flex-fill" v-if="!editmode">
            <table v-if="biodata.uuid && !editmode" cellpadding="3">
              <tr>
                <td>Warganegara</td>
                <td v-text="biodata.warganegara"></td>
              </tr>
              <tr>
                <td>No. KK</td>
                <td v-text="biodata.nokk"></td>
              </tr>
              <tr>
                <td>N I K</td>
                <td v-text="biodata.nik"></td>
              </tr>
              <tr v-if="biodata.no_passport">
                <td>No. Passport</td>
                <td v-text="biodata.no_passport"></td>
              </tr>
              <tr>
                <td>Nama Lengkap</td>
                <td v-text="biodata.nama_lengkap"></td>
              </tr>
              <tr>
                <td>Tempat, Tanggal Lahir</td>
                <td>
                  <span v-text="biodata.tempat_lahir"></span>,
                  <span
                    :text-content.prop="biodata.tanggal_lahir | date().toDate()"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>Usia</td>
                <td v-text="biodata.umur + ' Tahun'"></td>
              </tr>
              <tr>
                <td>Jenis Kelamin</td>
                <td
                  :text-content.prop="biodata.jenis_kelamin | enumV().to('LP')"
                ></td>
              </tr>
              <tr>
                <td>Anak Ke</td>
                <td>
                  <span v-text="biodata.anak_ke"></span> Dari
                  <span v-text="biodata.jum_saudara"></span> Bersaudara
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Tinggal Bersama</td>
                <td :text-content.prop="biodata.tinggal_bersama | fillDef"></td>
              </tr>
              <tr>
                <td>Pendidikan Terakhir</td>
                <td
                  :text-content.prop="biodata.pendidikan_terakhir | fillDef"
                ></td>
              </tr>
              <tr>
                <td>Phone1</td>
                <td :text-content.prop="biodata.phone1 | fillDef"></td>
              </tr>
              <tr>
                <td>Phone2</td>
                <td :text-content.prop="biodata.phone2 | fillDef"></td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>
              <tr>
                <td style="vertical-align: top" rowspan="4">Alamat</td>
                <td :text-content.prop="biodata.negara | fillDef"></td>
              </tr>
              <tr>
                <td :text-content.prop="biodata.provinsi | fillDef"></td>
              </tr>
              <tr>
                <td :text-content.prop="biodata.kabupaten | fillDef"></td>
              </tr>
              <tr>
                <td :text-content.prop="biodata.kecamatan | fillDef"></td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Jalan</td>
                <td :text-content.prop="biodata.jalan | fillDef"></td>
              </tr>
              <tr>
                <td>Kode Pos</td>
                <td :text-content.prop="biodata.kodepos | fillDef"></td>
              </tr>
              <!-- <tr v-if="keluarga"><td colspan="2"><hr></td></tr>
              <tr v-if="keluarga"><td>Sebagai Wali</td><td :text-content.prop="keluarga.sebagai_wali | enumV().to('YT')"></td></tr>
              <tr v-if="keluarga"><td>Status</td><td v-text="keluarga.status_relasi"></td></tr> -->
            </table>
          </div>

          <div class="flex-fill" v-if="editmode">
            <form>
              <div class="form-group">
                <div class="row">
                  <legend class="col-form-label col-sm-4 pt-0">
                    Kewarganegaraan
                  </legend>
                  <div class="col-sm-8">
                    <div class="form-check form-check-inline">
                      <input
                        name="warganegara"
                        v-validate="'required'"
                        v-model="isian.warganegara"
                        class="form-check-input"
                        type="radio"
                        id="wni"
                        value="WNI"
                      />
                      <label class="form-check-label" for="wni">WNI</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        name="warganegara"
                        v-model="isian.warganegara"
                        class="form-check-input"
                        type="radio"
                        id="wna"
                        value="WNA"
                      />
                      <label class="form-check-label" for="wna">WNA</label>
                    </div>
                    <div
                      v-show="errors.has('warganegara')"
                      class="invalid-feedback"
                      v-text="errors.first('warganegara')"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="no_passport" class="col-sm-4 col-form-label"
                  >Nomor Passport</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-6">
                      <input
                        :disabled="isian.warganegara == 'WNI'"
                        name="No Passport"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('No Passport') }"
                        v-model="isian.no_passport"
                        type="text"
                        class="form-control form-control-sm"
                        id="no_passport"
                        placeholder="Nomor Passport"
                        autocomplete=""
                      />
                      <div
                        v-show="errors.has('No Passport')"
                        class="invalid-feedback"
                        v-text="errors.first('No Passport')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="nokk" class="col-sm-4 col-form-label"
                  >Nomor KK</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-6">
                      <input
                        :disabled="isian.warganegara == 'WNA'"
                        name="No.KK"
                        v-validate="'required|numeric|digits:16'"
                        :class="{ 'is-invalid': errors.has('No.KK') }"
                        v-model="isian.nokk"
                        type="text"
                        class="form-control form-control-sm"
                        id="nokk"
                        placeholder="Nomor Kartu Keluarga"
                        autocomplete=""
                      />
                      <div
                        v-show="errors.has('No.KK')"
                        class="invalid-feedback"
                        v-text="errors.first('No.KK')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="nik" class="col-sm-4 col-form-label">NIK</label>
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-6">
                      <input
                        :disabled="isian.warganegara == 'WNA'"
                        name="NIK"
                        v-validate="'required|numeric|digits:16'"
                        :class="{ 'is-invalid': errors.has('NIK') }"
                        v-model="isian.nik"
                        type="text"
                        class="form-control form-control-sm"
                        id="nik"
                        placeholder="Nomor Induk Kependudukan"
                        autocomplete=""
                      />
                      <div
                        v-show="errors.has('NIK')"
                        class="invalid-feedback"
                        v-text="errors.first('NIK')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="nama_lengkap" class="col-sm-4 col-form-label"
                  >Nama Lengkap</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-8">
                      <input
                        name="nama"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('nama') }"
                        v-model="isian.nama_lengkap"
                        autocomplete="name"
                        type="text"
                        class="form-control form-control-sm"
                        id="nama_lengkap"
                        placeholder="Nama Lengkap"
                      />
                      <div
                        v-show="errors.has('nama')"
                        class="invalid-feedback"
                        v-text="errors.first('nama')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <legend class="col-form-label col-sm-4 pt-0">
                    Jenis Kelamin
                  </legend>
                  <div class="col-sm-8">
                    <div class="form-check form-check-inline">
                      <input
                        name="jenis kelamin"
                        :disabled="isian.uuid"
                        v-validate="'required|included:L,P'"
                        v-model="isian.jenis_kelamin"
                        class="form-check-input"
                        type="radio"
                        id="perempuan"
                        value="P"
                      />
                      <label class="form-check-label" for="perempuan">
                        Perempuan
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        name="jenis kelamin"
                        :disabled="isian.uuid"
                        v-model="isian.jenis_kelamin"
                        class="form-check-input"
                        type="radio"
                        id="laki-laki"
                        value="L"
                      />
                      <label jenis class="form-check-label" for="laki-laki">
                        Laki-Laki
                      </label>
                    </div>
                    <div
                      v-show="errors.has('jenis kelamin')"
                      class="invalid-feedback"
                      v-text="errors.first('jenis kelamin')"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="tempat_lahir" class="col-sm-4 col-form-label"
                  >Tempat Lahir</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-4">
                      <input
                        v-model="isian.tempat_lahir"
                        type="text"
                        class="form-control form-control-sm"
                        id="tempat_lahir"
                        placeholder="Tempat Lahir"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="tanggal_lahir" class="col-sm-4 col-form-label"
                  >Tanggal Lahir</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <Tanggal
                        name="tanggal lahir"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('tanggal lahir') }"
                        v-model="isian.tanggal_lahir"
                        type="date"
                        class="form-control form-control-sm"
                        id="tanggal_lahir"
                      />
                      <div
                        v-if="errors.has('tanggal lahir')"
                        class="invalid-feedback"
                        v-text="errors.first('tanggal lahir')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="anak_ke" class="col-sm-4 col-form-label"
                  >Anak Ke</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-2">
                      <label class="sr-only" for="anak_ke">Anak ke</label>
                      <input
                        name="anak ke"
                        v-validate="'required|numeric'"
                        :class="{ 'is-invalid': errors.has('anak ke') }"
                        v-model="isian.anak_ke"
                        type="number"
                        class="form-control form-control-sm mb-2"
                        id="anak_ke"
                        min="0"
                      />
                      <div
                        v-show="errors.has('anak ke')"
                        class="invalid-feedback"
                        v-text="errors.first('anak ke')"
                      ></div>
                    </div>
                    <div class="col-auto">
                      <div class="mb-2">
                        <label class="form-check-label" for="jum_saudara">
                          Dari
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label class="sr-only" for="jum_saudara"
                        >Jumlah Saudara</label
                      >
                      <input
                        name="jumlah saudara"
                        v-validate="'required|numeric'"
                        :class="{ 'is-invalid': errors.has('jumlah saudara') }"
                        v-model="isian.jum_saudara"
                        type="number"
                        class="form-control form-control-sm mb-2"
                        id="jum_saudara"
                        min="0"
                      />
                      <div
                        v-show="errors.has('jumlah saudara')"
                        class="invalid-feedback"
                        v-text="errors.first('jumlah saudara')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group row">
                <label for="tinggal_bersama" class="col-sm-4 col-form-label"
                  >Tinggal Bersama</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-4">
                      <input
                        v-model="isian.tinggal_bersama"
                        type="text"
                        class="form-control form-control-sm"
                        id="tinggal_bersama"
                        placeholder="Tinggal Bersama"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="pendidikan_terakhir" class="col-sm-4 col-form-label"
                  >Pendidikan Terakhir</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-4">
                      <input
                        v-model="isian.pendidikan_terakhir"
                        type="text"
                        class="form-control form-control-sm"
                        id="pendidikan_terakhir"
                        placeholder="Nama Pendidikan/Sekolah Terakhir"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group row">
                <label for="negara" class="col-sm-4 col-form-label"
                  >Negara</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select
                        name="negara"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('negara') }"
                        v-model="isian.id_negara"
                        @change="handleNegara"
                        id="negara"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Pilih Negara</option>
                        <option
                          v-for="(negara, id) in alamat.negara"
                          :key="id"
                          :value="negara.id"
                          v-text="negara.nama"
                        ></option>
                      </select>
                      <div
                        v-show="errors.has('negara')"
                        class="invalid-feedback"
                        v-text="errors.first('negara')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="provinsi" class="col-sm-4 col-form-label"
                  >Provinsi</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select
                        name="provinsi"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('provinsi') }"
                        v-model="isian.id_provinsi"
                        :disabled="isian.id_negara == null ? true : false"
                        @change="handleProvinsi"
                        id="provinsi"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Pilih Provinsi</option>
                        <option
                          v-for="(provinsi, id) in alamat.provinsi"
                          :key="id"
                          v-text="provinsi.nama"
                          :value="provinsi.id"
                        ></option>
                      </select>
                    </div>
                    <div
                      v-show="errors.has('provinsi')"
                      class="invalid-feedback"
                      v-text="errors.first('provinsi')"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="kabupaten" class="col-sm-4 col-form-label"
                  >Kabupaten</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select
                        name="kabupaten"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('kabupaten') }"
                        v-model="isian.id_kabupaten"
                        :disabled="isian.id_provinsi == null ? true : false"
                        @change="handleKabupaten"
                        id="kabupaten"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Pilih Kabupaten</option>
                        <option
                          v-for="(kabupaten, id) in alamat.kabupaten"
                          :key="id"
                          v-text="kabupaten.nama"
                          :value="kabupaten.id"
                        ></option>
                      </select>
                      <div
                        v-show="errors.has('kabupaten')"
                        class="invalid-feedback"
                        v-text="errors.first('kabupaten')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="kecamatan" class="col-sm-4 col-form-label"
                  >Kecamatan</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select
                        name="kecamatan"
                        v-validate="''"
                        :class="{ 'is-invalid': errors.has('kecamatan') }"
                        v-model="isian.id_kecamatan"
                        :disabled="isian.id_kabupaten == null ? true : false"
                        id="kecamatan"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Pilih Kecamatan</option>
                        <option
                          v-for="(kecamatan, id) in alamat.kecamatan"
                          :key="id"
                          :value="kecamatan.id"
                          v-text="kecamatan.nama"
                        ></option>
                      </select>
                      <div
                        v-show="errors.has('kecamatan')"
                        class="invalid-feedback"
                        v-text="errors.first('kecamatan')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group row">
                <label for="jalan" class="col-sm-4 col-form-label">Jalan</label>
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-md-8">
                      <input
                        name="jalan"
                        v-validate="'required|min:6'"
                        :class="{ 'is-invalid': errors.has('jalan') }"
                        v-model="isian.jalan"
                        type="text"
                        class="form-control form-control-sm"
                        id="jalan"
                        placeholder="Jalan atau Detail Alamat"
                        maxlength="99"
                      />
                      <div
                        v-show="errors.has('jalan')"
                        class="invalid-feedback"
                        v-text="errors.first('jalan')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="kodepos" class="col-sm-4 col-form-label"
                  >Kode POS</label
                >
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <input
                        name="kode pos"
                        v-validate="'required|min:5'"
                        :class="{ 'is-invalid': errors.has('kode pos') }"
                        v-model="isian.kodepos"
                        autocomplete="postal-code"
                        type="text"
                        class="form-control form-control-sm"
                        id="kodepos"
                        placeholder="Kode Pos"
                      />
                      <div
                        v-show="errors.has('kode pos')"
                        class="invalid-feedback"
                        v-text="errors.first('kode pos')"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <PesertaDidik
      :biodatas="biodatas"
      @refreshBio="$emit('refreshBio', true)"
    />
  </div>
</template>

<script>
import PesertaDidik from "./PesertaDidik";
import Tanggal from "../../components/Tanggal";

export default {
  components: { Tanggal, PesertaDidik },
  props: ["biodatas"],
  data() {
    return {
      biodata: {},
      editmode: false,
      isian: {},
      alamat: {
        negara: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
      },
      hasBerkasKK: false,
      hasBerkasKTP: false,
      hasBerkasAkta: false,
      hasBerkasKeteranganMenerima: false,
    };
  },
  methods: {
    action() {
      let doit = () => {
        this.$axios
          .put("/pendaftar/" + this.biodatas.uuid + "/datadiri", {
            warganegara: this.isian.warganegara,
            no_passport: this.isian.no_passport,
            nik: this.isian.nik,
            nokk: this.isian.nokk,
            nama_lengkap: this.isian.nama_lengkap,
            tempat_lahir: this.isian.tempat_lahir,
            tanggal_lahir: this.isian.tanggal_lahir,
            jenis_kelamin: this.isian.jenis_kelamin,
            anak_ke: parseInt(this.isian.anak_ke),
            jum_saudara: parseInt(this.isian.jum_saudara),
            tinggal_bersama: this.isian.tinggal_bersama,
            pendidikan_terakhir: this.isian.pendidikan_terakhir,
            id_kecamatan: this.isian.id_kecamatan,
            id_kabupaten: this.isian.id_kabupaten,
            jalan: this.isian.jalan,
            kodepos: this.isian.kodepos,
          })
          .then((res) => {
            this.$root.$emit("setNotif", {
              title: res.data.status,
              message: res.data.deskripsi,
              type: "success",
            });
            this.editmode = false;
            this.$emit("refreshBio", true);
          })
          .catch(() => {});
      };

      this.$validator.validateAll().then((result) => {
        if (result) doit();
      });
    },
    editTrigger() {
      this.editmode = true;
      // console.log(this.biodata)
      this.isian = {
        uuid: this.biodata.uuid,
        warganegara: this.biodata.warganegara,
        no_passport: this.biodata.no_passport,
        nik: this.biodata.nik,
        nokk: this.biodata.nokk,
        nama_lengkap: this.biodata.nama_lengkap,
        tempat_lahir: this.biodata.tempat_lahir,
        tanggal_lahir: this.biodata.tanggal_lahir,
        jenis_kelamin: this.biodata.jenis_kelamin,
        anak_ke: this.biodata.anak_ke,
        jum_saudara: this.biodata.jum_saudara,
        tinggal_bersama: this.biodata.tinggal_bersama,
        pendidikan_terakhir: this.biodata.pendidikan_terakhir,
        id_kabupaten: this.biodata.id_kabupaten,
        id_kecamatan: this.biodata.id_kecamatan,
        jalan: this.biodata.jalan,
        kodepos: this.biodata.kodepos,
      };
      this.getNegara();
      this.$psb.api
        .getFullAlamat({
          id_kecamatan: this.isian.id_kecamatan,
          id_kabupaten: this.isian.id_kabupaten,
        })
        .then((data) => {
          this.isian.id_negara = data.negara.id;
          this.isian.id_provinsi = data.prov.id;
          this.isian.id_kabupaten = data.kab.id;
          this.isian.id_kecamatan = data.kec.id;
          this.alamat.provinsi = data.negara.list_provinsi;
          this.alamat.kabupaten = data.prov.list_kabupaten;
          this.alamat.kecamatan = data.kab.list_kecamatan;
        })
        .catch(() => {});
    },
    getNegara() {
      this.$axios
        .get("/setting/negara")
        .then((res) => {
          this.alamat.negara = res.data;
        })
        .catch(() => {});
    },
    handleNegara() {
      this.$psb.api.Select.handleNegara(this.isian.id_negara, (data) => {
        this.isian.id_provinsi = data.provinsi;
        this.isian.id_kabupaten = data.kabupaten;
        this.isian.id_kecamatan = data.kecamatan;
        this.alamat.provinsi = data.list_provinsi;
      });
    },
    handleProvinsi() {
      this.$psb.api.Select.handleProvinsi(this.isian.id_provinsi, (data) => {
        this.isian.id_kabupaten = data.kabupaten;
        this.isian.id_kecamatan = data.kecamatan;
        this.alamat.kabupaten = data.list_kabupaten;
      });
    },
    handleKabupaten() {
      this.$psb.api.Select.handleKabupaten(this.isian.id_kabupaten, (data) => {
        this.isian.id_kecamatan = data.kecamatan;
        this.alamat.kecamatan = data.list_kecamatan;
      });
    },
  },
  computed: {
    isAllCompleted() {
      return (
        this.isFotodiriCompleted &&
        this.isKeluargaCompleted &&
        this.isIbuKandungCompleted &&
        this.isBerkasCompleted &&
        this.isMutasiBerkasCompleted
      );
    },
    isFotodiriCompleted() {
      return (
        this.biodata.fotodiri &&
        this.biodata.fotodiri.normal &&
        this.biodata.fotodiri.normal !== "/img/default/default_no_face_n.jpg"
      );
    },
    isKeluargaCompleted() {
      return this.biodata.keluarga && this.biodata.keluarga.length > 0;
    },
    isIbuKandungCompleted() {
      // ini diperlukan karena pembuatan rekening bank butuh nama ibu kandung
      let completed = false;
      if (this.isKeluargaCompleted) {
        for (let i = 0, len = this.biodata.keluarga.length; i < len; i++) {
          let relasiKeluarga = this.biodata.keluarga[i].relasi_keluarga;
          if (relasiKeluarga.kode_relasi === "12Y") {
            completed = true;
          }
        }
      }
      return completed;
    },
    isBerkasCompleted() {
      return this.hasBerkasKK && this.hasBerkasKTP && this.hasBerkasAkta;
    },
    isMutasiBerkasCompleted() {
      const isMutasi =
        this.biodata.pesertadidik &&
        this.biodata.pesertadidik.jenis_daftar === "MUTASI";

      if (!isMutasi) {
        return true;
      }

      return isMutasi && this.hasBerkasKeteranganMenerima;
    },
  },
  watch: {
    biodatas: {
      handler() {
        this.biodata = this.biodatas;

        if (Array.isArray(this.biodata.berkas)) {
          for (let i = 0, len = this.biodata.berkas.length; i < len; i++) {
            const idKatImg = this.biodata.berkas[i].id_kategori_image;
            if (idKatImg === 1) {
              this.hasBerkasKK = true;
            } else if (idKatImg === 2) {
              this.hasBerkasKTP = true;
            } else if (idKatImg === 3) {
              this.hasBerkasAkta = true;
            } else if (idKatImg === 22) {
              this.hasBerkasKeteranganMenerima = true;
            }
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.biodata = this.biodatas;
  },
};
</script>

<style lang="scss">
table {
  width: 100%;
}

table > tr > td:first-child {
  padding-right: 3rem;
  vertical-align: top;
}

table > tr > td.not-pading {
  padding-right: 0;
}

table > tr > td[colspan] {
  padding: 0;
}

@media (max-width: 992px) {
  table.edit > tr {
    display: block;
  }

  table.edit > tr > td {
    display: block;
  }
}
</style>
